import React from 'react'

import { Link } from 'gatsby'

const Header = () => (
  <header className="h-16 bg-white-500 flex justify-center items-center">
    <h1 className="text-green-500 text-3xl">
      <Link to="/">Simmr</Link>
    </h1>
  </header>
)

export default Header
