/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import Header from './header'

import '../assets/tailwind.css'
import '../assets/common/link.css'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div>
        <main>{children}</main>
        <footer className="flex flex-col justify-center items-center text-xs py-10">
          <ul className="flex justify-center pb-10">
            <li className="link px-5">
              <Link to="/privacy">
                Privacy Policy
              </Link>
            </li>
            <li className="link px-5">
              <Link to="/terms">
                Terms of Service
              </Link>
            </li>
            <li className="link px-5">
              <Link to="/guidelines">
                Community Guidelines
              </Link>
            </li>
          </ul>
          <div>
            © {new Date().getFullYear()} by Simmr.
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
